import * as React from "react";

import { Helmet } from "react-helmet";
import _ from "lodash";
import moment from "moment-timezone";
import { Page } from "../components/Page";
import Container from "../components/Container";
import DefaultLayout from "../layouts/default";
import { HtmlPageAttributes } from "../api";
import MainTitle from "../components/Text/MainTitle";
import SmallerMainTitle from "../components/Text/SmallerMainTitle";
import PreTitle from "../components/Text/PostTitle";
import Pins from "../components/Pins";
import SmallerInhalt, { InhaltElem } from "../components/Text/SmallerInhalt";
import Sponsors from "../components/Sponsors";
import ContentWrapper from "../components/Wrappers/ContentWrapper";

interface PinboardProps {
  pageContext: {
    attributes: HtmlPageAttributes;
    pinboard: any;
  };
}

const Pinboard: React.FC<PinboardProps> = (props) => {
  const {
    pageContext: {
      attributes: { content, name, teaser, description, keywords, slug },
      pinboard,
    },
  } = props;

  const sortedPins = _.orderBy(
    pinboard,
    ["attributes.publishedFrom"],
    ["desc"]
  );

  return (
    <DefaultLayout siteSlug={slug}>
      <Helmet>
        <title>{name} - STEP</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <Page>
        <ContentWrapper>
          <PreTitle>
            Advertising platform for jobs, news and activities
          </PreTitle>
          <SmallerMainTitle>
            Make your voice heard on our Pinboard
          </SmallerMainTitle>
          <Pins pins={sortedPins} />
          {/**
          {teaser && <div dangerouslySetInnerHTML={{ __html: teaser }} />}
          {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
           */}
          <InhaltElem className="center">
            <h4>
              Our Pinboard exists to enable Members, Students and Supporters, as
              well as commercial organisations to post notices, technical
              enquiries, employment opportunities and jobs sought including the
              circulation of an appropriate notice to all addresses on the
              database (ca. 1’000 addresses).
            </h4>
            <p>
              Fees: Personal advertisements CHF 150 <br />
              Commercial advertisements CHF 350 <br />
              free for STEP members and any person who has joined the STEP
              community supporter program
            </p>
            <p>
              Would you like to place an advertisement with us? Please send your
              details to the{" "}
              <a href="mailto:admin@step-ch-fl.com">STEP Office</a>.
            </p>
          </InhaltElem>
        </ContentWrapper>
      </Page>
    </DefaultLayout>
  );
};

export default Pinboard;
