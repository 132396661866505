import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import PinItem from "./PinItem";

const PinsGridElem = styled.div`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr;

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const PinsGrid = ({ pins }) => (
  <PinsGridElem>
    {pins && pins.map((pin) => <PinItem pin={pin} />)}
  </PinsGridElem>
);

export default PinsGrid;
