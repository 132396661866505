import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { breakpoints } from "../styles/variables";
import PinsGrid from "./Pins/PinsGrid";
import PinsFilter from "./Pins/PinsFilter";

const Pin = styled.section`
  margin-bottom: 69px;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 110px;
  }
`;

const FilterReset = styled.div`
  font: 16px / 19px "Slate Book";
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  > div {
    font: 16px / 19px "Slate Book";
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-top: 50px;
  }
`;

const Pins = ({ pins }) => {
  const defaultFilters = { category: "" };
  const [filters, setFilters] = useState(defaultFilters);

  const filteredPins = pins.filter((pin) => {
    if (filters.category !== "") {
      if (filters.category !== pin.category) {
        return false;
      }
    }
    if (moment(pin.attributes.publishedTo).isBefore(new Date())) {
      return false;
    }
    return true;
  });

  return (
    // define filters with useState here and pass down to filter and options components
    <Pin>
      <PinsFilter filters={filters} setFilters={setFilters} />
      <PinsGrid pins={filteredPins} />

      <FilterReset>
        <div>[{filteredPins.length}] RESULTS</div>&nbsp;&nbsp;|&nbsp;&nbsp;
        <div
          onClick={() => {
            setFilters(defaultFilters);
          }}
        >
          RESET FILTER
        </div>
      </FilterReset>
    </Pin>
  );
};

export default Pins;
