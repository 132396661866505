import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import Dropdown from "../Events/Dropdown";

const FilterElem = styled.div`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 30px;
  }

  &.centered {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 24px;
    > * {
      width: calc(25% - (24px / 2));
    }

    @media screen AND (min-width: ${breakpoints.tablet}px) {
      > * {
        width: calc(25% - ((24px * 5) / 6));
      }
    }

    @media screen AND (min-width: ${breakpoints.desktop}px) {
      width: calc(25% - ((24px * 3) / 4));
    }
  }
`;

const PinsFilter = ({ filters, setFilters }) => {
  const changeCategory = (category) => {
    if (category === "All") {
      setFilters({ ...filters, category: "" });
    } else {
      setFilters({ ...filters, category });
    }
  };

  return (
    <FilterElem>
      <Dropdown
        title="CATEGORY"
        colored
        options={["All", "jobs-vacant", "jobs-wanted", "miscellaneous", "news"]}
        changeFunction={changeCategory}
        filterName="category"
        filters={filters}
      />
    </FilterElem>
  );
};

export default PinsFilter;
