import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const TitleElem = styled.h3`
  font: 18px / 22px "Slate Light";
  text-transform: uppercase;
  //text-align: center;
  margin-bottom: 40px;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    font: 24px / 29px "Slate Light";
    margin-top: -35px;
    margin-bottom: 15px;
  }
`;

const PreTitle = ({ children }) => <TitleElem>{children}</TitleElem>;

export default PreTitle;
